
  import { Vue, Component } from 'vue-property-decorator'
  import getApi from '@/api'

  const auth = getApi('auth')

  @Component
  export default class ChangePassword extends Vue {
    validatePass = (rule: any, value: any, callback: any) => {
      console.log(value)
      if (value === '') {
        callback(new Error('Please input the new password'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          // @ts-ignore
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }

    validatePass3 = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('Please input the old password'))
      }
      callback()
    }

    validatePass2 = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.ruleForm.newPass) {
        callback(new Error("Two inputs don't match!"))
      } else {
        callback()
      }
    }

    ruleForm = {
      newPass: '',
      checkPass: '',
      oldPass: ''
    }
    rules = {
      oldPass: [{ validator: this.validatePass3, trigger: 'blur' }],
      newPass: [{ validator: this.validatePass, trigger: 'blur' }],
      checkPass: [{ validator: this.validatePass2, trigger: 'blur' }]
    }

    isLoading = false

    async handleSubmit() {
      try {
        //@ts-ignore
        this.$refs.ruleForm.validate(async (value: boolean) => {
          if (value) {
            if (this.isLoading) {
              return
            }
            this.isLoading = true
            await auth.changePassword(this.ruleForm.oldPass, this.ruleForm.newPass)
            this.isLoading = false
            this.$message.success(this.$t('updateSuccess') as string)
            this.handleReset()
            return Promise.resolve()
          } else {
            this.isLoading = false
            return Promise.reject(false)
          }
        })
      } catch (error) {
        this.$message.error(`The old password is incorrect.`)
      }
    }

    handleReset() {
      this.ruleForm = {
        newPass: '',
        checkPass: '',
        oldPass: ''
      }
    }
  }
