
  import { Vue, Component } from 'vue-property-decorator'
  import { UserInfoInterface } from '@/interface/BaseInterface'
  import { namespace } from 'vuex-class'
  import { cloneDeep } from 'lodash'
  import MemberApi from '../api/memberApi'
  import getApi from '@/api'

  const tAuth = namespace('tAuth')

  const memberApi: MemberApi = getApi('member')

  @Component
  export default class ChangeProfile extends Vue {
    @tAuth.State('user') user!: UserInfoInterface

    data: UserInfoInterface = {
      _id: '',
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: ''
    }

    isLoading = false

    handleSubmit() {
      if (this.isLoading) {
        return
      }
      // @ts-ignore
      this.$refs.ruleForm.validate(async (value: boolean) => {
        if (value) {
          this.isLoading = true
          const result = {
            email: this.data.email,
            firstName: this.data.firstName,
            lastName: this.data.lastName,
            phoneNumber: this.data.phoneNumber
          }
          const data = await memberApi.updateProfile(result)
          this.isLoading = false
          this.$emit('submit', data)
          this.$message.success(this.$t('createSuccess') as string)
          return Promise.resolve()
        } else {
          this.isLoading = false
          return Promise.reject(false)
        }
      })
    }

    handleReset() {
      if (this.isLoading) {
        return
      }
      console.log(123)
    }

    created() {
      this.data = cloneDeep(this.user)
    }
  }
